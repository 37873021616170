import React from 'react';
import { Typography, SvgIcon, IconButton } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableTitle: {
            color: theme.palette.primary.main,
            padding: '0 16px',
            fontSize: '1.15rem',
            paddingLeft:4,
        },
        tableTitleIcon: {
            display: 'flex',
            alignSelf: 'center',
            // color: '#6c6c6c'
            color: theme.palette.grey[600],

        },
        backBtn: {
            margin: theme.spacing(1),
            padding: 8,
            // marginLeft: -12
        },
        helpBtn: {
            margin: theme.spacing(1),
            padding: 8,
            // color: theme.palette.primary.main
        },
        lightTooltip: {
            // backgroundColor: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            // color: 'rgba(0, 0, 0, 0.87)'
            color: theme.palette.common.white,
            boxShadow: theme.shadows[1],
            // fontSize: 11,
        },
        popper: {
            // width: 500
        }
    }),
);

interface Props {
    title?: string | null | undefined;
    icon?: string | null | undefined;
    history?: any;
    showHelp?: boolean;
    helpText?: PropTypes.ReactNodeLike;
}

const CustomTableTitle = (props: Props) => {
    const classes = useStyles({});
    const goBack = () => props.history.goBack();
    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {
                props.history &&
                <IconButton aria-label="back"
                    className={classes.backBtn} onClick={goBack}>
                    <ArrowBackIcon />
                </IconButton>
            }
            {
                props.icon &&
                <div className={classes.tableTitleIcon}>
                    <SvgIcon>
                        <path d={props.icon} />
                    </SvgIcon>
                </div>
            }
            <Typography variant='h6' 
                className={classes.tableTitle}>{props.title}</Typography>
            {
                props.showHelp &&
                props.helpText &&
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <Tooltip
                        classes={{
                            tooltip: classes.lightTooltip,
                            popper: classes.popper
                        }}
                        arrow
                        interactive
                        PopperProps={{
                            disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={tooltipOpen}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={props.helpText}
                    >
                        <IconButton aria-label="help"
                            className={classes.helpBtn}
                            onClick={handleTooltipOpen}>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </ClickAwayListener>
            }
        </div>
    );
};

CustomTableTitle.defaultProps = {
    title: '',
    icon: null
};
export default CustomTableTitle;