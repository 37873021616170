const defaultOptions = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  preventDuplicate: true,
  autoHideDuration: 3000
}


export const ToastError = {
  ...defaultOptions,
  variant: 'error'
}
export const ToastInfo = {
  ...defaultOptions,
  variant: 'info'
}
export const ToastSuccess = {
  ...defaultOptions,
  variant: 'success'
}
export const ToastWarning = {
  ...defaultOptions,
  variant: 'warning'
}