import React from 'react';
import { Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageWrapper: {
      backgroundColor: '#fff9c4',
      border: '1px solid rgba(0,0,0,0.12)',
      padding: theme.spacing(1),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 8,
      borderRadius: 4,
    },
    textStyle: {
      paddingLeft: 6,
      paddingRight: 6,
      whiteSpace: 'pre-wrap'
    }
  }),
);

interface Props {
  isVisible?: boolean;
  type?: string;
}

const ExportStatusBar = (props: Props) => {
  const classes = useStyles({});

  return (
    <>
      {
        props.isVisible && !props.type &&
        <div>
          <div className={classes.messageWrapper}>
            <Typography align={'center'}
              className={classes.textStyle}
            >{'Exporting Data. Please wait...'}
            </Typography>
          </div>
        </div>
      }
      {
        props.isVisible &&  props.type === 'order-form' &&
        <div>
          <div className={classes.messageWrapper}>
            <Typography align={'center'}
              className={classes.textStyle}
            >{'Exporting Order Form. Please wait...'}
            </Typography>
          </div>
        </div>
      }
    </>
  );
};
ExportStatusBar.defaultProps = {
  isVisible: false,
};
export default ExportStatusBar;