import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import { Route, Switch, useParams } from "react-router-dom";
import { Theme, createStyles, makeStyles, fade } from '@material-ui/core/styles';
import { Paper, Divider } from '@material-ui/core';

import { Product, CommodityGroup } from '../../../products/models';
import { PaginatedResponse, Location } from '../../../shared/models';
// import { KioskRoutes } from '../../Routes/Routes';
import { getProductsByPage, getCommodityGroups, getKioskProducts } from '../../../products/api/Api';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import composeRequest from '../../../shared/api/core';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import NavBar from '../components/NavBar/NavBar';
import { ErrorStatusBar } from '../../../shared/components';
import { KioskRoutes, KioskPaths } from '../../Routes/Routes';
import { useLocation, useHistory } from 'react-router-dom';
import { Home } from '../../screens';
// import { ProductDetail } from '../../screens';
import debounce from 'lodash/debounce';
import { verifyKioskToken, LOGIN_HEADERS } from '../../api/auth';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { saveUserLoginData } from '../../data/actions';
import throttle from 'lodash/throttle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    componentroot: {
      display: 'flex',
      // overflow: 'hidden',
    },
    title: {
      display: 'flex',
      width: '10rem',
      // display: 'none',
      // [theme.breakpoints.up('sm')]: {
      //     display: 'block',
      // },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    // searchWrapper: {
    //   backgroundColor: 'white',
    //   borderRadius: 4,
    //   border: '1px solid rgba(0, 0, 0, 0.12)',
    //   marginBottom: 12
    // },
    // content: {
    //   padding: theme.spacing(2),
    //   backgroundColor: '#f6f7fC',
    //   height: '100%',
    //   overflow: 'auto'
    // },
    content: {
      height: '100%',
      overflow: 'auto',
      flexGrow: 1,
      marginTop: '64px',
      // backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
  },
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    mainContent: {
      flexGrow: 1,
      display: 'flex',
    },
    routeContent: {
      flexGrow: 1,
      padding: theme.spacing(3),
      backgroundColor: '#f6f7fC',
      maxWidth: '100%',
      overflow: 'auto',
    },
  }),
);
interface Props {
  onSearch: (event) => void;
  clearSearch: () => void;
  searchTerm: string;
  placeholder?: string;
  saveUserData: (data: any) => void;
}

// const regex = /([0-9a-zA-Z]|[.]|[,]|[']|["]|[-]|[_]|[#]|[^%]|[^;])+$/;
const regex =/[0-9a-zA-Z]|[,."#]|[^%^;]/g;

const KioskLayout = (props: any) => {
  const classes = useStyles({});
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [delayedSearchTerm, setDelayedSearchTerm] = useState('');
  const [isAuthorized, setAuthorized] = useState(false);
  const [authorizing, setAuthorizing] = useState(false);
  const [verificationComplete, setVerificationComplete] = useState(false);
  const { token, path, searchterm } = useParams<{ token: string, path: string, searchterm: string; }>();

  const delayedSave = useCallback(debounce(q => setDelayedSearchTerm(q), 500), []);
  let val = "";

  // const delayedSaveSearchTerm = debounce((q) => {
  //   setDelayedSearchTerm(q);
  // }, 500, {
  //   'maxWait': 1000,
  // });
  const onSearch = (e) => {
    console.log(e);
    let value = e.target.value;
    const notRegex = /^[;%]/g;

    if (value.match(regex) || value === "") {
      // console.log("Value", value) 
      // console.log("array",value.match(regex))

      value = value ? value.match(regex).toString().split(',').join('') : ''
      setSearchTerm(value);
      delayedSave(value)
    }
    
    // delayedSave(value);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setDelayedSearchTerm('');
    val = "";
    // document.body.removeEventListener('keypress', onKeyPress);
    // document.body.addEventListener('keypress', onKeyPress);
    // props.searchTerm ='';
    // console.log('onclear search val', val);
    // document.body.removeEventListener('barcode_scan_input', handleEvent);
    // document.body.addEventListener('barcode_scan_input', handleEvent);

  };

  
  useEffect(() => {
    console.log("SEARCH-TERM",searchTerm)
  }, [searchTerm]);

  const onKeyPress = (e) => {
    e.preventDefault();
    // console.log('onKeyPress', e);
    // console.log('onKeyPress val', val);
    if (e && e.key) {
      const value = e.key;
      if (value.match(regex) || value === "") {
      val = val + value;
      // if (value.match(regex) || value === "") {
      setSearchTerm(val);
      delayedSave(val);
      }
      // delayedSave(val);
      // if (value.match(regex) || value === "") {
      //   const val = searchTerm + value;
      //   setSearchTerm(val);
      //   delayedSave(val);
      // }
    }

  };

  const onKeyUp = (e) => {
    console.log('onKeyUp', e);
    delayedSave(val);
  };

  const onKeyDown = (e) => {
    console.log('onKeyUp', e);
    delayedSave(val);
  };

  // useEffect(() => {
  //   document.body.addEventListener('keypress', onKeyPress);
  //   // document.body.addEventListener('keydown', onKeyDown);
  //   // document.body.addEventListener('keyup', onKeyUp);

  //   return () => {
  //     document.body.removeEventListener('keypress', onKeyPress);
  //     // document.body.removeEventListener('keydown', onKeyDown);
  //     // document.body.removeEventListener('keyup', onKeyUp);
  //   };
  // }, []);

  useEffect(() => {
    // console.log("test")
    setAuthorized(false);
    setAuthorizing(true);
    verifyKioskToken(token)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data) => {
        setAuthorizing(false);
        const authHeaders = { ...LOGIN_HEADERS, 'USER-AUTH-TOKEN': data.auth_token };
        props.saveUserData(data);
        try {
          localStorage.setItem('headers', JSON.stringify(authHeaders));
        } catch (e) {
          // saving error
          console.log('error in localStorage');
        }
        setAuthorized(true);
        setVerificationComplete(true);
        

      })
      .catch((errorData: any) => {
        console.log('sign-in error', errorData);
        setAuthorizing(false);
        setAuthorized(false);
        setVerificationComplete(true);
      });
  }, []);
  

  // useEffect(() => {
  //   document.addEventListener("searchTerm", function(event) {
  //   console.log("Received post message", event);
  //   setSearchTerm(event.data)
  // }, false);
  // }, []);
  const handleEvent=(message)=>{
    const parsedData= JSON.parse(message.data)
    const searchInput = parsedData.code
    setSearchTerm(searchInput);
    delayedSave(searchInput)
    console.log("searchInput",searchInput)
  }


  useEffect(() => {
    
    document.body.addEventListener("barcode_scan_input", handleEvent);
    return () => {
      document.body.removeEventListener('barcode_scan_input', handleEvent);

    };
  }, []);

  // useEffect(() => {

  //   document.body.addEventListener("message", ()=>{
  //     console.log("here")
  //     // var details = JSON.parse(data.data);
  //   })
    
  // }, []);


 const getScannedInput=(e)=>{
  e.preventDefault();
  if (e && e.data) {
    const value = e.data;
    val = val + value;
    setSearchTerm(val);
    delayedSave(val);
  }
 }

  const getRoute = () => {
    // console.log("SearchTerm", searchTerm);
    return (
      <div className={classes.componentroot}>
        <Route render={() => (
          <NavBar
            // onSearchInputBlur={() => {
            //   document.body.removeEventListener('keypress', onKeyPress);
            //   document.body.addEventListener('keypress', onKeyPress);
            //   val = searchTerm;
            // }}
            // onSearchInputFocus={() => {
            //   // console.log('onSearchInputFocus removing keypress');
            //   document.body.removeEventListener('keypress', onKeyPress);
            // }}
            onSearch={onSearch}
            searchTerm={searchTerm}
            clearSearch={clearSearch}
          />
         )} />
       <main className={classes.content}>
          {/* <Route render={() => ( */}
            <Home
              // searchTerm={searchTerm}
              searchTerm={delayedSearchTerm}
              history={history}
              clearSearch={clearSearch} />
          {/* /> */}
        </main>
      </div>
    );
  };




  return (
    <div className={classes.root}>
      {verificationComplete &&
        isAuthorized &&
        !authorizing &&
        path == 'home' &&
        getRoute()}
      {authorizing && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <CircularProgress color="primary" />
          <div style={{ padding: '16px' }}>Authorizing...</div>
        </div>
      )}
      {verificationComplete && !isAuthorized && !authorizing && (
        <div style={{ padding: '16px', textAlign: 'center' }}>
          <Typography variant="h4">403 FORBIDDEN</Typography>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    saveUserData: (data: any) => dispatch(saveUserLoginData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KioskLayout);