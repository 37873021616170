import { combineReducers } from 'redux';
import {
    GET_PRODUCTS,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAILURE,
    SET_SELECTED_PRODUCT,
    CLEAR_SELECTED_PRODUCT,
    SEARCH_PRODUCTS,
    SEARCH_PRODUCTS_FAILURE,
    SEARCH_PRODUCTS_SUCCESS,
    GET_PRODUCT_COUNT,
    GET_PRODUCT_COUNT_SUCCESS,
    GET_PRODUCT_COUNT_FAILURE,
    GET_PRODUCT_SUMMARY,
    GET_PRODUCT_SUMMARY_FAILURE,
    GET_PRODUCT_SUMMARY_SUCCESS,
    GET_PRODUCT_DETAIL,
    GET_PRODUCT_DETAIL_FAILURE,
    GET_PRODUCT_DETAIL_SUCCESS,
    CLEAR_PRODUCT_LIST,
} from '../actions/actionTypes';
import { Product } from '../../models';
import { PaginatedResponse } from '../../../shared/models';

const initialState = {
    data: {
        count: 0,
        results: [],
        next: null,
        previous: null,
    } as PaginatedResponse<Product>, // products
    productList: [],
    selectedProduct: null as Product,
    isFetching: false,
    success: false,
    error: false,
    errorData: null,
};

export function productsReducer(state = initialState, action: any) {
    // console.log(action)
    let nextProducts = [];
    let oldList = [];
    let productList = [];
    switch (action.type) {
        case GET_PRODUCTS:
            return {
                ...state,
                isFetching: true,
                success: false,
                error: false,
                errorData: null,
            };
        case GET_PRODUCTS_SUCCESS:
            // console.log(action.data)
            nextProducts = [...action.data.results];
            oldList = [];
            if (!action.resetList) {
                oldList = [...state.productList];
            }
            productList = oldList.concat(nextProducts);
            return {
                ...state,
                isFetching: false,
                data: action.data,
                productList: productList,
                success: true,
                error: false,
                errorData: null,
            };
        case GET_PRODUCTS_FAILURE:
            return {
                ...state,
                isFetching: false,
                success: false,
                error: true,
                errorData: action.err ? action.err : null,
            };
        case GET_PRODUCT_DETAIL:
            return {
                ...state,
                isFetching: true,
                success: false,
                error: false,
                errorData: null,
            };
        case GET_PRODUCT_DETAIL_SUCCESS:
            return {
                ...state,
                selectedProduct: action.data,
                isFetching: false,
                success: false,
                error: false,
                errorData: null,
            };
        case GET_PRODUCT_DETAIL_FAILURE:
            return {
                ...state,
                isFetching: false,
                success: false,
                error: true,
                errorData: action.err ? action.err : null,
            };
        case SEARCH_PRODUCTS:
            return {
                ...state,
                isFetching: true,
                success: false,
                error: false,
                errorData: null,
            };
        case SEARCH_PRODUCTS_SUCCESS:
            // console.log(action.data)
            nextProducts = [...action.data.results];
            oldList = [];
            if (!action.resetList) {
                oldList = [...state.productList];
            }
            productList = oldList.concat(nextProducts);
            return {
                ...state,
                isFetching: false,
                data: action.data,
                productList: productList,
                success: true,
                error: false,
                errorData: null,
            };
        case SEARCH_PRODUCTS_FAILURE:
            return {
                ...state,
                data: initialState.data,
                isFetching: false,
                success: false,
                error: true,
                errorData: action.err ? action.err : null,
            };
        case SET_SELECTED_PRODUCT:
            return {
                ...state,
                selectedProduct: action.data,
                isFetching: false,
                success: false,
                error: false,
                errorData: null,
            };
        case CLEAR_SELECTED_PRODUCT:
            return {
                ...state,
                selectedProduct: null,
                isFetching: false,
                success: false,
                error: false,
                errorData: null,
            };
        case CLEAR_PRODUCT_LIST:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}

const initialProductCountState = {
    productCount: 0,
    isFetching: false,
    success: false,
    error: false,
};

export function productCountReducer(state = initialProductCountState, action: any) {
    switch (action.type) {
        case GET_PRODUCT_COUNT:
            return {
                ...state,
                isFetching: true,
                success: false,
                error: false,
            };
        case GET_PRODUCT_COUNT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                productCount: action.data.productCount,
                success: true,
                error: false,
            };
        case GET_PRODUCT_COUNT_FAILURE:
            return {
                ...state,
                isFetching: false,
                success: false,
                error: true,
            };
        default:
            return state;
    }
}

const initialProductSummaryState = {
    withoutBarcodeCount: 0,
    unMappedCount: 0,
    totalCount: 0,
    isFetching: false,
    success: false,
    error: false,
    errorData: null,
};

export function productSummaryDataReducer(state = initialProductSummaryState, action: any) {
    switch (action.type) {
        case GET_PRODUCT_SUMMARY:
            return {
                ...state,
                isFetching: true,
                success: false,
                error: false,
                errorData: null,
            };
        case GET_PRODUCT_SUMMARY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                ...action.data,
                success: true,
                error: false,
                errorData: null,
            };
        case GET_PRODUCT_SUMMARY_FAILURE:
            return {
                ...state,
                isFetching: false,
                success: false,
                error: true,
                errorData: action.err ? action.err : null,
            };
        default:
            return state;
    }
}

export const productsModuleReducer = combineReducers({
    products: productsReducer,
    productCountData: productCountReducer,
    productSummaryData: productSummaryDataReducer,
});
