import React, { useEffect, useState, useRef } from 'react';

import { Typography, Snackbar, SvgIcon, Switch } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { ErrorCode, ERROR_CODES } from '../../models';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        messageWrapper: {
            backgroundColor: GlobalStyles.errorBackground,
            border: '1px solid ' + GlobalStyles.red,
            padding: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 8,
            borderRadius: 4,
        },
        textStyle: {
            color: GlobalStyles.errorText,
            paddingLeft: 6,
            paddingRight: 6,
            whiteSpace: 'pre-wrap'
        }
    }),
);

interface Props {
    message?: string;
    errorCode?: string | ErrorCode | null;
    isVisible?: boolean;
    displayMultiline?: boolean;
    messageContainerStyle?: any;
    textStyle?: any;
    displayErrorCode: boolean;
}

const ErrorStatusBar = (props: Props) => {
    // console.log(props);
    const classes = useStyles({});

    return (
        <div>
            {
                props.isVisible &&
                !props.displayMultiline &&
                <div className={classes.messageWrapper}
                    style={props.messageContainerStyle}>
                    <Typography align={'center'}
                        className={classes.textStyle}
                        style={props.textStyle}>{props.displayErrorCode ? props.message + ' Error Code: ' + props.errorCode : props.message}
                    </Typography>
                </div>
            }
            {
                props.isVisible &&
                props.displayMultiline &&
                <div className={classes.messageWrapper}
                    style={props.messageContainerStyle}>
                    <Typography align={'center'}
                        className={classes.textStyle}
                        style={props.textStyle}>{props.displayErrorCode ? props.message + '\nError Code: ' + props.errorCode : props.message}
                    </Typography>
                </div>
            }
        </div>
    );
};
ErrorStatusBar.defaultProps = {
    message: 'This action could not be performed!',
    errorCode: ERROR_CODES.NO_PERMISSIONS,
    isVisible: true,
    displayMultiline: false,
    displayErrorCode: true
};
export default ErrorStatusBar;