import { LOG_OUT, SAVE_USER_DATA } from '../actions/actionTypes';


const initialState = {
    userData: null as any,
}

export default function authReducer(state = initialState, action: any) {
    switch(action.type) {
        case SAVE_USER_DATA:
            return {
                ...state,
                userData: action.data, 
            }
        case LOG_OUT: return {
            userData: null
        }
        default: return state;

    }
}