import React, { useEffect, useState } from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
// import { AuthLayout } from 'components/src/modules/page/layouts';
// import SignInScreen from 'components/src/modules/auth/screens/SignIn/SignInScreen.web';
import KioskLayout from 'components/src/modules/kiosk/layout/KioskLayout/KioskLayout';
import { KioskSignInScreen } from 'components/src/modules/kiosk/screens/';
import { NotFound404 } from 'components/src/modules/kiosk/components/NotFound404/NotFound404';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { Notifier } from 'components/src/modules/shared/components/';
// import { getAuthorizedMenuItems } from './AuthorizedRoutes';
import { PageRedirect } from 'components/src/modules/page/components';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#1976d2',
		},
		secondary: {
			main: '#ffb300',
		},
	},
});

const isUserAuthenticated = () => {
	return window.localStorage.getItem('headers') && window.localStorage.getItem('persist:root');
};

const PrivateRoute = (props: any) => {
	const { component: Component, drawerMenuData, ...rest } = props;
	return (
		<Route
			{...rest}
			render={(props) => isUserAuthenticated()
				? <Component {...props} drawerMenuData={drawerMenuData} />
				: <Redirect to={{ pathname: '/kiosk-sign-in', state: { from: props.location } }} />}
		/>
	);
};

const AppNavigator = (props: any) => {


	return (
		<MuiThemeProvider theme={theme}>
			<SnackbarProvider maxSnack={3}>
				<Notifier />
				<Router>
					<Switch>
						<Route path="/kiosk/:token/:path" render={() => <KioskLayout />} />
						<Route path="*" component={NotFound404} />
					</Switch>
				</Router>
			</SnackbarProvider>
		</MuiThemeProvider>
	);
};


const mapStateToProps = (state: any) => {
	return {
		userLocalData: state.userLocalData
	};
};

export default AppNavigator;
