import React, { Component } from 'react';
import { connect } from 'react-redux';
import { History } from 'history';
import { Button, LinearProgress, Paper, TextField, Typography } from '@material-ui/core';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { API_URLS, LOGIN_HEADERS } from '../../api/auth';
import { checkStatus, handleError, parseJSON } from '../../../shared/api/core';
import { Toast, enqueueSnackbar, ToastError } from '../../../shared/components/Notifier';
import { Location } from '../../../shared/models';
import { saveUserLoginData } from '../../data/actions';
import { saveUserLocation, setSelectedKioskProfile } from '../../../../store/actions/';
import { KioskProfileSettings } from '../../../kiosk/models';


interface Props {
    userLocalData: any;
    saveUserData: (data: any) => void;
    setUserLocation: (location: Location) => void;
    saveSelectedKioskProfile: (kioskprof: KioskProfileSettings) => void;
    showToast: (toast: Toast) => void;
    history: History;
    location: any;
};

interface State {
    username: string;
    password: string;
    loader: boolean;
}

class KioskSignInScreen extends Component<Props, State> {

    state = {
        username: '',
        password: '',
        loader: false
    };

    render() {
        return (
            <div style={styles.container}>
                <Paper style={styles.card}>
                    {
                        this.state.loader &&
                        <LinearProgress color="secondary" style={styles.progressBar} />
                    }
                    <div style={styles.header}>
                        <Typography style={{
                            fontSize: 32,
                            textAlign: 'center',
                            color: GlobalStyles.primaryColor
                        }}>Konnect Kiosk</Typography>
                    </div>
                    <form style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                        onSubmit={this._signInAsync}
                    >
                        <TextField
                            style={{ width: '55%' }}
                            id="username"
                            label="Username"
                            autoComplete="username"
                            value={this.state.username}
                            onChange={(event) => {
                                this.setState({ username: event.target.value });
                            }}
                            margin="normal" />
                        <TextField
                            style={styles.input}
                            id="standard-password-input"
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            onChange={(event) => {
                                this.setState({ password: event.target.value });
                            }}
                            margin="normal" />
                        <div style={styles.btnWrapper}>
                            <Button variant="contained"
                                color="primary"
                                style={styles.signInBtn}
                                type="submit">
                                Sign In
                            </Button>
                        </div>
                    </form>
                </Paper>
            </div>
        );
    }

    _signInAsync = async (event: any) => {
        event.preventDefault();
        this.setState({ loader: true });
        const requestHeaders = { ...LOGIN_HEADERS };
        const URL = API_URLS.loginUrl;
        fetch(URL, {
            headers: { ...requestHeaders },
            method: 'POST',
            body: JSON.stringify(
                {
                    username: this.state.username,
                    password: this.state.password
                })
        })
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then(data => {
                console.log(data)
                this.props.saveUserData(data);
                // this.loadUserSettings(data);

                const authHeaders = { ...requestHeaders, 'USER-AUTH-TOKEN': data.auth_token };
                try {
                    localStorage.setItem('headers', JSON.stringify(authHeaders));
                } catch (e) {
                    // saving error
                    // console.log('error in localStorage');
                }
                if (this.props.location.state && this.props.location.state.from) {
                    this.props.history.push(this.props.location.state.from);
                } else {
                    this.props.history.push('/kiosk/home');
                }
            })
            .catch((errorData: any) => {
                console.log('sign-in error', errorData);
                const defaultMsg = "Oops!! An error ocurred.";
                let toastMsg;
                if (errorData) {
                    if (errorData.data && errorData.data.error === 'Invalid Credentials') {
                        toastMsg = 'Error! Invalid Credentials';
                    } else if (errorData.status && errorData.status === 500) {
                        toastMsg = "Server Error! please try again later.";
                    } else if (errorData.response && errorData.response.status === 0) {
                        toastMsg = "Could not sign-in! Please check you internet connection.";
                    } else {
                        toastMsg = defaultMsg;
                    }
                } else {
                    toastMsg = defaultMsg;
                }

                this.setState({ loader: false });
                this.props.showToast({ message: toastMsg, options: ToastError });
            });
    };

    // loadUserSettings(data: any) {

    //     const { locations, kiosk_profile } = data;
    //     // if (!data.settings.userLocation) {
    //     //     if (locations.length === 1) {
    //     //         this.props.setUserLocation(data.locations[0]);
    //     //     }
    //     // } else {
    //     //     const selectedLocationId = data.settings.userLocation;
    //     //     const filteredLocations = locations.filter((l: Location) => l.id === selectedLocationId);
    //     //     if (filteredLocations.length > 0) {
    //     //         this.props.setUserLocation(filteredLocations[0]);
    //     //     } else {
    //     //         if (locations.length === 1) {
    //     //             this.props.setUserLocation(data.locations[0]);
    //     //         }
    //     //     }
    //     // }
    //     // if (data.settings.labelerModule) {
    //     //     const template = data.settings.labelerModule.selectedTemplate;
    //     //     if (template) {
    //     //         this.props.setSelectedTemplate(template);
    //     //     }
    //     // }
    //     if (kiosk_profile && kiosk_profile.settings) {    
    //         this.props.saveSelectedKioskProfile(kiosk_profile.settings);
    //     }
    // }
}

const styles = {
    container: {
        flex: 1,
        alignItems: 'center',
        paddingHorizontal: 24,
        paddingBottom: 0,
        paddingTop: 64,
        backgroundColor: GlobalStyles.primaryColor
    },
    progressBar: {
        padding: 1
    },
    header: {
        paddingVertical: 12,
        paddingTop: 24
    },
    card: {
        width: '60%',
        maxWidth: 511.3,
        minWidth: 340,
        elevation: 12,
        margin: 'auto'
    },
    form: {
        marginBottom: 32,
        alignItems: 'center',
        justifyContent: 'center',
    },
    btnWrapper: {
        padding: 16,
        paddingTop: 32,
        paddingBottom: 32,
    },
    input: {
        width: '55%',
        fontSize: 16
    },
    label: {
        fontSize: 18
    },
    passswordInput: {
        fontSize: 32,
        textAlign: 'center',
        padding: 6,
    },
    signInBtn: {
        borderRadius: 4,
        width: 120,
        maxWidth: 120,
        alignSelf: 'center',
    },
    btnText: {
        color: 'white',
        fontSize: 18,
    }
};

const mapStateToProps = (state: any) => {
    return {
        userLocalData: state.userLocalData
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        saveUserData: (data: any) => dispatch(saveUserLoginData(data)),
        saveSelectedKioskProfile: (kioskprof: KioskProfileSettings) => dispatch(setSelectedKioskProfile(kioskprof)),
        setUserLocation: (location: any) => dispatch(saveUserLocation(location)),
        showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(KioskSignInScreen);