import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { removeSnackbar } from './redux/actions';
import { Notification } from './models';

interface Props {
  notifications: Notification[];
  enqueueSnackbar: (msg: string, config: any) => any;
  removeSnackbar: (key: number) => any;
};

interface State {
  displayed: number[];
}

class Notifier extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      displayed: [],
    };
  }


  storeDisplayed = (key: any) => {
    this.setState(({ displayed }) => ({
      displayed: [...displayed, key],
    }));
  };

  render() {
    const { notifications, enqueueSnackbar, removeSnackbar } = this.props;
    const { displayed } = this.state;

    notifications.forEach((notification) => {
      setTimeout(() => {
        // If notification already displayed, abort
        if (displayed.indexOf(notification.key) > -1) return;
        // Display notification using notistack
        // console.log(typeof (notification.message));
        if (typeof (notification.message) !== 'object') {
          enqueueSnackbar(notification.message, notification.options);
          // Add notification's key to the local state
          this.storeDisplayed(notification.key);
          // Dispatch action to remove the notification from the redux store
          removeSnackbar(notification.key);
        }
      }, 1);
    });

    return null;
  }
}

const mapStateToProps = (state: any) => ({
  notifications: state.toast.notifications,
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ removeSnackbar }, dispatch);
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Notifier));
