export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const GET_USER_ACTIVE_COUNT = 'GET_USER_ACTIVE_COUNT';
export const GET_USER_ACTIVE_COUNT_SUCCESS = 'GET_USER_ACTIVE_COUNT_SUCCESS';
export const GET_USER_ACTIVE_COUNT_FAILURE = 'GET_USER_ACTIVE_COUNT_FAILURE';

export const GET_USER_SUMMARY = 'GET_USER_SUMMARY';
export const GET_USER_SUMMARY_SUCCESS = 'GET_USER_SUMMARY_SUCCESS';
export const GET_USER_SUMMARY_FAILURE = 'GET_USER_SUMMARY_FAILURE';