import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography, FormControl, InputLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    //   KeyboardTimePicker,
    KeyboardDatePicker,
    DatePicker
} from '@material-ui/pickers';

// import { API_URLS } from '../../api/ApiUrls';
// import composeRequest from '../../../shared/api/core';
// import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';

interface ConfirmModalProps {
    onCancel: () => any;
    isOpen: boolean;
    dialogTitle: string;
    dialogMessage1: string;
    dialogMessage2: string;
    onConfirm: () => any;
    confirmBtnText: string;
    dialogMessage1Color?: string;
    dialogMessage2Color?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // backgroundColor: '#F3F5F7'
            backgroundColor: theme.palette.background.paper,
        },
        list: {
            width: '100%',
            // maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        paper: {
            width: 200,
            height: 230,
            overflow: 'auto',
        },
        button: {
            margin: theme.spacing(0.5, 0),
        },
        textField: {
            width: '100%'
        }
    }),
);

export default function ConfirmModal(props: ConfirmModalProps) {
    // console.log(props)
    const classes = useStyles({});
    const [open, setOpen] = useState(props.isOpen);

    return (
        <div>
            <Dialog
                fullWidth={false}
                maxWidth={'xs'}
                open={props.isOpen ? props.isOpen : false}
                onClose={() => props.onCancel()}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    {props.dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="body1"
                            style={{
                                color: props.dialogMessage1Color ?
                                    props.dialogMessage1Color : 'initial'
                            }}>{props.dialogMessage1}</Typography>
                    </DialogContentText>
                    <Typography variant="body1"
                        style={{
                            color: props.dialogMessage1Color ?
                                props.dialogMessage2Color : 'initial'
                        }}>{props.dialogMessage2}</Typography>
                </DialogContent>
                <DialogActions style={{ paddingLeft: 24, paddingRight: 24 }}>
                    <Button onClick={() => props.onCancel()}>
                        Cancel
                    </Button>
                    <Button onClick={() => props.onConfirm()} color="primary">
                        {props.confirmBtnText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}