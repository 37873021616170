import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { useLocation, useHistory } from 'react-router-dom';
import { Paper, Divider } from '@material-ui/core';
import { Theme, createStyles, makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import { Product, CommodityGroup } from '../../../products/models';
import { PaginatedResponse, Location } from '../../../shared/models';

import { getProductsByPage, getCommodityGroups, getProductsByPageKiosk } from '../../../products/api/Api';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import { defaultGridPaginationQuery, initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import {
  EMPTY_ROW_MSG,
  ERROR_MSG,
  ERROR_500,
  NO_PERMISSIONS_ERROR_MSG,
} from '../../../shared/lib/Localization';
import { GET_RECEIPT_ITEMS_BY_RECEIPT_ID_FAILURE } from '../../../inventory/data/actions/actionTypes';
import ProductDetail from '../ProductDetail/ProductDetail.web';
import { getProduct } from '../../api/Api';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { getProductPriceForPos } from '../../../products/lib/price';

const initialPageData = {
  count: 0,
  next: null,
  previous: null,
  results: []
} as PaginatedResponse<Product>;


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
      float: 'left'
    },
    noImageRow: {
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    cardContent: {
      paddingBottom: '16px !important'
    },
    grow: {
      flexGrow: 1,
    },
    title: {
      display: 'flex',
      width: '10rem',
    },
    content: {
      padding: theme.spacing(2),
      backgroundColor: '#f6f7fC',
      height: '100%',
      overflow: 'auto'
    },
    grid: {
      flexGrow: 1,
    },
    tile: {
      border: `4px solid ${theme.palette.grey['300']}`,
      cursor: 'pointer',
      // minHeight: 366,
      width: 230,
      // "&:hover": {
      //   border: `4px solid ${theme.palette.grey['300']}`,
      //   // backgroundColor: 'rgb(7, 177, 77, 0.42)'
      // }
    },
    tileContent: {
      display: 'flex',
      flexDirection: 'column',
      height: '50%',
    },
    tileTextContainer: {
      // backgroundColor: theme.palette.grey['100'],
      height: 100,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    loadingIcon: {
      width: 500,
      height: 80,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    card: {
      padding: theme.spacing(1),
      height: "100%"
    },
    menuBar: {
      marginBottom: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent:"space-between",
    },
    row: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    rowCenter: {
      minHeight: 150,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    }
  }),
);
interface Props {
  searchTerm: string | undefined;
  userData: any;
  history: any;
  clearSearch: () => void;
}
const pageSize = 25;

const Home = (props: Props) => {
  const classes = useStyles({});
  const history: any = useHistory();
  const [pageData, setPageData] = useState(initialPageData);
  const [isLoading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    ...defaultGridPaginationQuery,
    pageSize: pageSize
  });
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productData, setProductData] = useState({ ...history.location.state } as Product);
  const [pageLoader, setPageLoader] = useState(false);
  const [currentSearchTerm, setCurrentSearchTerm] = useState('')
  console.log("H")
  useEffect(() => {
    if (props.searchTerm.length > 3) {
      fetchProducts();
    }
    else {
      setPageData(initialPageData);
      setSelectedProduct(null);
    }
  }, [pagination, props.searchTerm]);


  const fetchProducts = () => {
    setLoading(true);
    setSelectedProduct(null);
    const { authData } = props.userData;
    // console.log('155', authData);
    if (authData) {
      const { kiosk_profile } = authData;
      if (kiosk_profile && kiosk_profile.business) {
        const sb = kiosk_profile.business;
        getProductsByPageKiosk(pagination.page, pageSize, props.searchTerm, sb)
          .catch(handleError) // handle network issues
          .then(checkStatus)
          .then(parseJSON)
          .then((data: PaginatedResponse<Product>) => {
            if (data.results.length === 1) {
              fetchProductDetail(data.results[0]);
              setCurrentSearchTerm(props.searchTerm)
              props.clearSearch();
            }
            setPageData(data);
            setLoading(false);
            // const currentSearchTerm = [...props.searchTerm];
          })
          .catch((error: any) => {
            setLoading(false);
            if (error) {
              if (error.status && error.status === 500) {
                setEmptyDataSourceMessage(ERROR_MSG);
                setErrorStatusVisible(false);
              }
            }
          });
      }
    }
  };

  const fetchProductDetail = (selectedProduct: any) => {
    setLoading(true);

    const uuid = selectedProduct.uuid;
    getProduct(uuid)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: Product) => {
        setLoading(false);
        if (isErrorStatusVisible) {
          setErrorStatusVisible(false);
        }
        setSelectedProduct(data);
      })
      .catch((error: any) => {
        setLoading(false);

      });


  };

  const getImagePath=(productData: any)=>{
    if (productData){
      if(productData.image_data && productData.image_data.length > 0){
        let defaultImg = productData.image_data.filter(i=>i.default === true)
        return defaultImg && defaultImg.length ===1 ? defaultImg[0].media_core.source : null
      }
      return getDefaultImagePath()
    }
  }



  const renderProducts = (products) => {
    return products.map((p, index) => {
      return (
        <Grid key={index} item>
          <Paper elevation={0}
            className={classes.tile}>
            <div className={classes.tileContent} onClick={() => setSelectedProduct(p)} >
              <div style={{ height: 150, width: 150, margin: 'auto', marginTop: 16, }}>
                <img
                  alt={p.name}
                  height={'100%'}
                  width={'100%'}
                  src={getImagePath(p)} 
                  style={{ objectFit:'contain' }}
                />
              </div>
              <div className={classes.tileTextContainer}>
                <div style={{ padding: 16, paddingBottom: 8 }}>
                  <Typography variant="subtitle2" align="center">{p.name}</Typography>
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
      );
    });
  };


  const getDefaultImagePath = () => {
    const imagePath = '/assets/img-default.png';
    return window.location.origin + imagePath;
  };


  const displayPrice = () => {
    if (props.userData) {
      const { kiosk_profile } = props.userData.authData;
      if (kiosk_profile) {
        const { settings } = kiosk_profile;
        if (settings) {
          const { productDetail } = settings;
          if (productDetail && productDetail.productPrices === true) {
            let price = "---";
            const orgunit = settings.orgUnitId ? settings.orgUnitId : null;
            price = getProductPriceForPos(selectedProduct, orgunit);
            return (
              <Typography variant="h4" align="center">
                {`$${price}`}
              </Typography>
            );
          }
        }
      }
    } return null;
  };

  const displayImage = () => {
    const { kiosk_profile } = props.userData.authData;
    if (kiosk_profile) {
      const { settings } = kiosk_profile;
      if (settings) {
        const { productDetail } = settings;
        if (productDetail && productDetail.productImage === true) {
          return true;
        }
      }
    } return false;
  };

  const navigateBack = () => {
    if (pageData &&
      pageData.results &&
      pageData.results.length === 1) {
      if (props && props.clearSearch) {
        props.clearSearch();
      }
    }
    setSelectedProduct(null);
    setCurrentSearchTerm('');
  };



  const productDetailCard = () => {

    return (
      <Container maxWidth="lg">
        <Paper elevation={0} style={{ padding: 24 }}>
          <div className={classes.menuBar}  >
            <div style={{display:"flex",  alignItems:"center",}} >
                <Button
                className={classes.menuButton}
                onClick={navigateBack}
                style={{ backgroundColor: '#fff' }}
                variant="outlined"
              >
                Back
              </Button>
              <Typography variant="h5">Product Detail</Typography>
            </div>
            <div >
              {currentSearchTerm ? <Typography style={{ fontSize: 16 }}> {`Lookup: ${currentSearchTerm}`}</Typography>
                : null}
            </div>
          </div>
          
          {displayImage() === true &&
            <Grid container spacing={2} style={{ paddingTop: 8 }} direction="row">
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                <Card className={classes.card} variant="outlined">
                  <CardContent>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <img
                        src={getImagePath(selectedProduct)} 
                        style={{ objectFit:'contain', height:'50%', width:'50%' }}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                <Card className={classes.card} variant="outlined" >
                  <CardContent>
                    <Grid container className={classes.row}>
                      <Typography variant="h6" style={{ fontSize: 22 }} >
                        {selectedProduct ? selectedProduct.name ? selectedProduct.name : '' : ''}
                      </Typography>
                    </Grid>
                    <Grid container className={classes.row}>
                      {displayPrice()}
                    </Grid>
                    <Grid container className={classes.row}>
                      <div style={{ width: '100%', textAlign: 'center' }}>
                        {/* <Typography variant="h6">BARCODE</Typography> */}
                      </div>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          }
          {displayImage() === false &&
            <Grid container spacing={2} style={{ paddingTop: 8 }} direction="row">
              <Grid item xs={12} >
                <Card className={classes.card} variant="outlined">
                  <CardContent classes={{ root: classes.cardContent }} >
                    <Grid container className={classes.noImageRow}>
                      <Typography variant="h6">{selectedProduct ? selectedProduct.name ? selectedProduct.name : '' : ''}</Typography>
                    </Grid>
                    <Grid container className={classes.noImageRow}>
                      {displayPrice()}
                    </Grid>
                    <Grid container className={classes.row}>
                      <div style={{ width: '100%', textAlign: 'center' }}>
                      </div>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          }
        </Paper>
      </Container>

    );
  };

  // const productList = () => {
  //   if (!selectedProduct && !isLoading && pageData && pageData.results.length > 1) {
  //     return (
  //       <Grid container className={classes.grid} spacing={2}>
  //         <Grid item xs={12}  >
  //           <Grid container justify="center" spacing={1} >
  //             {
  //               pageData.results.map(p => {
  //                 return (
  //                   <div
  //                     key={p.uuid}
  //                     onClick={() => setSelectedProduct(p)}>
  //                     {renderProducts(pageData.results)}
  //                   </div>);
  //               })
  //             }

  //           </Grid>
  //         </Grid>
  //       </Grid>
  //     );
  //   }
  //   return null;
  // };

  return (
    <div style={{ width: '100%' }}>
      { props.searchTerm.length <= 3 && !currentSearchTerm && props.searchTerm === '' && 
        <div style={{ width: '100%', textAlign: 'center', paddingTop: 16 }}>
          <Typography variant='h4' style={{ color: GlobalStyles.secondary }}>
            Scan or Search A Product
          </Typography>
        </div>}
      <Container>
        <div style={{
          margin: 'auto',
          paddingTop: 16,
          display: 'flex',
          width: '100%',
          justifyContent: 'center'
        }}>
          <div style={{ width: '100%' }}>
            {
              isLoading &&
              <div className={classes.rowCenter}>
                <CircularProgress />
              </div>
            }
            {
              !isLoading &&
              pageData.results.length === 0 &&
              props.searchTerm.length > 3 && 
              <div className={classes.rowCenter}>
                {/* <Typography>No Search Results Found.${props.searchTerm}</Typography> */}
                <Typography>{`No Search Results Found for ${props.searchTerm}`}</Typography>
              </div>
            }
            <Grid container className={classes.grid} spacing={2}>
              <Grid item xs={12}  >
                <Grid container justify="center" spacing={1} >
                  {
                    !isLoading &&
                    pageData.results &&
                    pageData.results.length > 1 &&
                    !selectedProduct &&
                    renderProducts(pageData.results)
                  }
                  {
                    !isLoading &&
                    selectedProduct &&
                    productDetailCard()
                  }
                </Grid>
              </Grid>
            </Grid>
            {/* {productList()} */}
            

          </div>
        </div>
      </Container>
    </div>
  );

};
const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

export default connect(mapStateToProps, null)(Home);
