import React, { useEffect, useState, useRef } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Theme, createStyles, makeStyles, fade } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ProductsWithoutBarcodeScreen } from '../../../reports/screens';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            display: 'flex',
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1),
                width: '100%',
            },
            '&:focus': { flexGrow: 1 }
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
            width: '100%'
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            // transition: theme.transitions.create('width'),
            width: '100%',
            height: '2em',
            [theme.breakpoints.up('sm')]: {
                width: '60ch',
                // '&:focus': {
                //     width: '45ch',
                // },
            },
        },

    }),
);

interface Props {
    onSearch: (event) => void;
    clearSearch: () => void;
    searchTerm: string;
    placeholder?: string;
    onFocus?: () => void;
    onBlur?: () => void;
}
export const SearchBar = (props: Props) => {
    const classes = useStyles({});
    const { onSearch, clearSearch, searchTerm, onFocus, onBlur } = props;
    const [inputFocus, setInputFocus] = useState(true);
    const inputRef = useRef(null);
    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                placeholder={'Search Products..'}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                onChange={onSearch}
                value={searchTerm}
                autoFocus
                ref={inputRef}
                onFocus={onFocus}
                onBlur={onBlur}
            // defaultValue ={searchTerm}

            />
            <div>
                <IconButton color="inherit" onClick={() => {
                    clearSearch();
                    inputRef.current.focus();
                }}>

                    <CloseIcon />
                </IconButton>
            </div>
        </div>
    );
};