import { combineReducers } from 'redux';
import {
    SET_KIOSK_PROFILE
} from '../../actions/actionTypes';
import { KioskProfileSettings } from '../../../modules/kiosk/models/models';
import kioskauthReducer from '../../../modules/kiosk/data/reducers/kioskauthReducer';

const initialState = null;

const selectedKioskProfileReducer = (state = null as KioskProfileSettings, action: any) => {
    switch (action.type) {
        case SET_KIOSK_PROFILE:
            if (action.data) {
                return { ...action.data };
            } else {
                return state;
            }
        default: return state;
    }
};

export const userDataReducer = combineReducers({
    authData: kioskauthReducer,
    selectedKioskProfile: selectedKioskProfileReducer
});
