import { 
    GET_PRODUCT_BY_CODE, GET_PRODUCT_BY_CODE_SUCCESS, GET_PRODUCT_BY_CODE_FAILURE, CLEAR_PRODUCT_FROM_STORE,
    GET_PRODUCT_BY_CODE_AND_SUPPLIER, GET_PRODUCT_BY_CODE_AND_SUPPLIER_SUCCESS, GET_PRODUCT_BY_CODE_AND_SUPPLIER_FAILURE
} from '../actions/actionTypes';

export interface productInitialState {
    product: {
        name: string
    },
    isFetching: boolean,
    success: boolean,
    error: boolean,
    errorData: any
}

const initialState = {
    product: {
        name: ''
    },
    isFetching: false,
    success: false,
    error: false,
    errorData: null as any
}

export default function productByCodeReducer(state = initialState, action: any) {
    switch(action.type) {
        case GET_PRODUCT_BY_CODE: return {
            ...state, 
            isFetching: true, 
            success: false,
            error: false,
            errorData: null
        }
        case GET_PRODUCT_BY_CODE_SUCCESS: return {
            ...state,
            isFetching: false,
            product: action.data,
            success: true,
            error: false,
            errorData: null
        }
        case GET_PRODUCT_BY_CODE_FAILURE: return {
            ...state,
            product: initialState.product,
            isFetching: false,
            success: false,
            error: true,
            errorData: action.error
        }
        case GET_PRODUCT_BY_CODE_AND_SUPPLIER: return {
            ...state, 
            isFetching: true, 
            success: false,
            error: false,
            errorData: null
        }
        case GET_PRODUCT_BY_CODE_AND_SUPPLIER_SUCCESS: return {
            ...state,
            isFetching: false,
            product: action.data,
            success: true,
            error: false,
            errorData: null
        }
        case GET_PRODUCT_BY_CODE_AND_SUPPLIER_FAILURE: return {
            ...state,
            product: initialState.product,
            isFetching: false,
            success: false,
            error: true,
            errorData: action.error
        }
        case CLEAR_PRODUCT_FROM_STORE: return {
            ...state,
            product: {
                name: '',
                price: null,
            },
            isFetching: false,
            success: false,
            error: false,
            errorData: null
        }
        default: return state;

    }
}