import { API_CONFIG } from '../../shared/api/config';

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
    loginUrl: BASE_URL + 'core/kiosk/login',
    verifyKioskTokenUrl: BASE_URL + 'core/auth/verify_kiosk_token',
};

export const LOGIN_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'API-KEY': API_CONFIG.API_KEY,
};

export const verifyKioskToken = (token: string) => {
    const data = { token };
    const requestHeaders = { ...LOGIN_HEADERS };
    const URL = API_URLS.verifyKioskTokenUrl;
    return fetch(URL, {
        headers: { ...requestHeaders },
        method: 'POST',
        body: JSON.stringify(data),
    });
};
