import { Product } from '../models';
import moment from 'moment';

export const roundNumber = (number: number, decimalPlaces: number) => {
  if (!isNaN(number)) {
    return number.toFixed(decimalPlaces);
  }
};

export const getProductPriceForPos = (product: Product, orgUnitId?: string, code?: string) => {
  if (product && product.prices) {
    if (product.prices.length > 0) {
      if (orgUnitId) {
        const storePrices = product.prices.filter(p => p.organizationalUnit === orgUnitId);
        if (storePrices.length > 0) {
          return roundNumber(storePrices[0].value, 2);
        }
      }
      // case dated prices - return the the most recent datedPrice
      const datedPrices = product.prices.filter(p => p.validFrom !== null);
      if (datedPrices.length > 0) {
        datedPrices.sort(sortByDate);
        return roundNumber(datedPrices[0].value, 2);
      }
      // if no dated prices then look for coded prices and return the first value
      const codedPrices = product.prices.filter(p => p.articleCode !== null);
      if (codedPrices.length > 0) {
        return roundNumber(codedPrices[0].value, 2);
      }
    }
    return null
  }
  return null
};

export const getProductPriceByPriceListId = (product: Product, priceListId: string, defaultPriceListId?: string, orgUnitId?: string) => {
  if (product && product.prices) {
    if (product.prices.length > 0 && priceListId) {
      const prices = product.prices.filter(p => p.priceList && p.priceList.uuid === priceListId);
      if (prices.length > 0) {
        if (defaultPriceListId && orgUnitId && defaultPriceListId === priceListId) {
          const storePrices = product.prices.filter(p => p.organizationalUnit === orgUnitId);
          if (storePrices.length > 0) {
            return roundNumber(storePrices[0].value, 2);
          }
        }
        const datedPrices = prices.filter(p => p.validFrom !== null);
        if (datedPrices.length > 0) {
          datedPrices.sort(sortByDate);
          return roundNumber(datedPrices[0].value, 2);
        }
        // if no dated prices then look for coded prices and return the first value
        const codedPrices = product.prices.filter(p => p.articleCode !== null);
        if (codedPrices.length > 0) {
          return roundNumber(codedPrices[0].value, 2);
        }
      }
    }
  }
  return null;
};

const sortByDate = (a, b) => {
  const momentA = moment(a.validFrom);
  const momentB = moment(b.validFrom);
  if (momentA.isAfter(momentB)) {
    return -1;
  } else if (momentA.isBefore(momentB)) {
    return 1;
  }
  return 0;
};