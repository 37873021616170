import React, { useEffect, useState, useRef } from 'react';
import { Theme, createStyles, makeStyles, fade } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import { logOutKioskUser } from '../../../data/actions';
import { getProductsByPage } from '../../../../products/api/Api';
import { handleError, checkStatus, parseJSON } from '../../../../shared/api/core';
import composeRequest from '../../../../shared/api/core';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../../shared/lib/Localization';
import { useHistory } from 'react-router-dom';
import { SearchBar } from '../../../../shared/components/SearchBar/SearchBar';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            overflow: 'hidden'
        },
        toolbar: theme.mixins.toolbar,
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                // width: 'auto',
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
            width: '100%'
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            // [theme.breakpoints.up('md')]: {
            //     width: '70%',
            // },
        },
        box: {
            alignItems: 'center',
            justifyContent: 'center'
        }

    }),
);

interface Props {
    onSearch: (event) => void;
    clearSearch: () => void;
    onSearchInputBlur?: () => void;
    onSearchInputFocus?: () => void;
    searchTerm: string;
    placeholder?: string;
    signOutUser: () => void;
    // authData: any;
}


const NavBar = (props: Props) => {
    console.log(props);
    const classes = useStyles({});
    const history = useHistory();
    const navigate = (path) => { history.push(path); };
    const { onSearch, clearSearch, searchTerm, placeholder, onSearchInputBlur, onSearchInputFocus } = props;


    const _signOutAsync = async () => {
        try {
            await localStorage.removeItem('headers');
            props.signOutUser();
        } catch {
            // console.log('error in localStorage');
        }
        setTimeout(() => (window.location.href = '/'), 200);
    };

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Container >
                    <Toolbar style={{ minHeight: 70 }}>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', }}>
                                <div className={classes.box}>
                                    <SearchBar
                                        onSearch={onSearch}
                                        onBlur={onSearchInputBlur}
                                        onFocus={onSearchInputFocus}
                                        searchTerm={searchTerm}
                                        clearSearch={clearSearch}
                                        placeholder={'SearchProducts...'} />
                                </div>
                            </div>
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userData: state.userLocalData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        signOutUser: () => dispatch(logOutKioskUser()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
