import { SET_SELECTED_LOCATION, SET_SELECTED_LOCATIONS, SET_SELECTED_BUSINESS } from './actionTypes';
import { Location, LocationBusiness } from '../../modules/shared/models';

export const saveUserLocation = (location: Location) => {
    return {
        type: SET_SELECTED_LOCATION,
        location
    };
};

export const setSelectedLocations = (locations: Location[]) => {
    return {
        type: SET_SELECTED_LOCATIONS,
        locations
    };
};

export const setSelectedBusiness = (business: LocationBusiness | undefined) => {
    return {
        type: SET_SELECTED_BUSINESS,
        business
    };
};