export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';
export const CLEAR_PRODUCT_LIST = 'CLEAR_PRODUCT_LIST';

export const GET_PRODUCT_DETAIL = 'GET_PRODUCT_DETAIL';
export const GET_PRODUCT_DETAIL_SUCCESS = 'GET_PRODUCT_DETAIL_SUCCESS';
export const GET_PRODUCT_DETAIL_FAILURE = 'GET_PRODUCT_DETAIL_FAILURE';

export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const CLEAR_SELECTED_PRODUCT = 'CLEAR_SELECTED_PRODUCT';

export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const SEARCH_PRODUCTS_SUCCESS = 'SEARCH_PRODUCTS_SUCCESS';
export const SEARCH_PRODUCTS_FAILURE = 'SEARCH_PRODUCTS_FAILURE';

export const GET_PRODUCT_BY_CODE = 'GET_PRODUCT_BY_CODE';
export const GET_PRODUCT_BY_CODE_SUCCESS = 'GET_PRODUCT_BY_CODE_SUCCESS';
export const GET_PRODUCT_BY_CODE_FAILURE = 'GET_PRODUCT_BY_CODE_FAILURE';

export const GET_PRODUCT_BY_CODE_AND_SUPPLIER = 'GET_PRODUCT_BY_CODE_AND_SUPPLIER';
export const GET_PRODUCT_BY_CODE_AND_SUPPLIER_SUCCESS = 'GET_PRODUCT_BY_CODE_AND_SUPPLIER_SUCCESS';
export const GET_PRODUCT_BY_CODE_AND_SUPPLIER_FAILURE = 'GET_PRODUCT_BY_CODE_AND_SUPPLIER_FAILURE';

export const CLEAR_PRODUCT_FROM_STORE = 'CLEAR_PRODUCT_FROM_STORE';

export const GET_PRODUCT_COUNT = 'GET_PRODUCT_COUNT';
export const GET_PRODUCT_COUNT_SUCCESS = 'GET_PRODUCT_COUNT_SUCCESS';
export const GET_PRODUCT_COUNT_FAILURE = 'GET_PRODUCT_COUNT_FAILURE';

export const GET_PRODUCT_SUMMARY = 'GET_PRODUCT_SUMMARY';
export const GET_PRODUCT_SUMMARY_SUCCESS = 'GET_PRODUCT_SUMMARY_SUCCESS';
export const GET_PRODUCT_SUMMARY_FAILURE = 'GET_PRODUCT_SUMMARY_FAILURE';