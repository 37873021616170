import { API_CONFIG } from "../../shared/api/config";
import composeRequest from '../../shared/api/core';

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
  productByCodeUrl: BASE_URL + 'korona/products/get_by_code/',
  productsUrl: BASE_URL + 'korona/products/',
  commodityGroupsUrl: BASE_URL + 'korona/commodity-groups/',
  kioskProductsUrl: BASE_URL + 'korona/kiosk-products/',
  supplierProductsUrl: BASE_URL + 'korona/supplier-products/',
  productCountUrl: BASE_URL + 'korona/products/count/',
  productSummaryDataUrl: BASE_URL + 'korona/products/summary/',
};

export const getProduct = (uuid: string) => {
  const URL = API_URLS.productsUrl + `${uuid}/`;
  return composeRequest(URL, 'GET', null);
};

export const getProductsByPage = (page: number, pageSize: number,
  searchTerm: string, business_id: number | string) => {
  let URL = `${API_URLS.productsUrl}?web=true&business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
  searchTerm = searchTerm ? searchTerm.trim() : '';
  if (searchTerm) {
    URL = `${URL}&search=${searchTerm}`;
  }
  return composeRequest(URL, 'GET', null);
};

export const getCommodityGroups = (searchTerm: string, business_id: number | string) => {
  let URL = `${API_URLS.commodityGroupsUrl}?business_ids=${business_id}`;
  searchTerm = searchTerm ? searchTerm.trim() : '';
  if (searchTerm) {
    URL = `${URL}&search=${searchTerm}`;
  }
  return composeRequest(URL, 'GET', null);
};

export const getKioskProducts = (page: number, pageSize: number,
  searchTerm: string, business_id: number | string, commodityGroupId: number | string) => {
  let URL = `${API_URLS.kioskProductsUrl}?business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
  searchTerm = searchTerm ? searchTerm.trim() : '';
  if (commodityGroupId) {
    URL = `${URL}&commodityGroup__uuid=${commodityGroupId}`;
  }
  if (searchTerm) {
    URL = `${URL}&search=${searchTerm}`;
  }
  return composeRequest(URL, 'GET', null);
};
