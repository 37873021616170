import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';


import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { Product, CurrentOrderSummaryItem, PastOrderSummaryItem } from '../../../products/models/models';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '../../../shared/components';
import { Location, LocationBusiness } from '../../../shared/models';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import { ErrorStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_MSG, ERROR_500, NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';

import { BusinessRouterPaths } from '../../../page/Routes/RouterPaths';
import { PageToolbar } from '../../../page/components';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { getProductsByPage, getCommodityGroups, getKioskProducts } from '../../../products/api/Api';
import { getProduct } from '../../api/Api';
import { setPageLoading } from '../../../../store/actions';
import _groupBy from 'lodash/groupBy';
import { renderCard } from '../../../dashboard/components';
import { renderCardItem } from '../../../dashboard/components/Common';
import { setSelectedBusiness } from '../../../../store/actions/userLocation';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { getProductPriceForPos } from '../../../products/lib/price';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
      float: 'left'
    },
    menuBar: {
      marginBottom: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row'
    },
    paper: {
      padding: theme.spacing(3),
      // marginBottom: theme.spacing(2),
    },
    title: {
      color: theme.palette.primary.main,
      alignSelf: 'center'
    },
    row: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    noImageRow: {
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: 6,
      width: '100%',
      fontSize: '0.875rem'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    card: {
      padding: theme.spacing(1),
      height: "100%"
    },
    panelRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '16px'
    },
    panelCol: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    fullWidth: {
      width: '100%'
    },
    flex1: {
      flex: 1
    },
    flex2: {
      flex: 2
    },
    tile: {
      border: `4px solid ${theme.palette.grey['100']}`,
      cursor: 'pointer',
      // minHeight: 366,
      width: 230,
      "&:hover": {
        border: `4px solid ${theme.palette.grey['300']}`,
        // backgroundColor: 'rgb(7, 177, 77, 0.42)'
      }
    },
    tileContent: {
      display: 'flex',
      flexDirection: 'column',
      height: '50%',
    },
    tileTextContainer: {
      // backgroundColor: theme.palette.grey['100'],
      height: 100,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    panelRowHeader: {
      backgroundColor: '#f5f5f5',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '16px'
    },
    panelHeaderText: {
      fontSize: '0.95rem'
    },
    noDataRow: {
      width: '100%',
      padding: 16
    },
    tag: {
      marginRight: 8,
      marginBottom: 8
    },
    cardContent: {
      paddingBottom: '16px !important'
    }
  }),
);
// interface Props {
//     products: any;
//     screenProps: any;
//     getSelectedProductDetail: (id: string) => void;
//     selectProduct: (p: Product) => void;
//   }

const prevRoute = location.pathname.slice(0, location.pathname.length - 36);


const ProductDetail = (props: any) => {
  // console.log(props);
  const classes = useStyles({});
  const history: any = useHistory();
  const { location } = history;
  const [productData, setProductData] = useState({ ...history.location.state } as Product);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);


  useEffect(() => {
    props.setPageLoader(true);

    const { uuid } = history.location.state as Product;
    if (uuid) {
      getProduct(uuid)
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then((data: Product) => {
          props.setPageLoader(false);
          if (isErrorStatusVisible) {
            setErrorStatusVisible(false);
          }
          setProductData(data);
        })
        .catch((error: any) => {
          props.setPageLoader(false);
        });
    }
  }, []);


  const getImagePath = () => {
    const imagePath = '/assets/img-default.png';
    return window.location.origin + imagePath;
  };

  const displayPrice = () => {
    const { kiosk_profile } = props.userData;
    if (kiosk_profile) {
      const { settings } = kiosk_profile;
      if (settings) {
        const { productDetail } = settings;
        if (productDetail && productDetail.productPrices === true) {
          let price = "---";
          const orgunit = settings.orgUnitId ? settings.orgUnitId : null;
          price = getProductPriceForPos(productData, orgunit);
          return (
            <Typography variant="h4" align="center">
              {`$${price}`}
            </Typography>
          );
        }
      }
    }
    return null;
  };

  const displayImage = () => {
    const { kiosk_profile } = props.userData;
    if (kiosk_profile) {
      const { settings } = kiosk_profile;
      if (settings) {
        console.log('displayImage', settings);
        const { productDetail } = settings;
        if (productDetail && productDetail.productImage === true) {
          return true;
        } else { return false; }
      } else { return false; }
    } else { return false; }
  };

  const navigateBack = () => {
    props.history.goBack();
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={0} style={{ padding: 24 }}>
        {/* <div className={classes.menuBar}  >
          <Button
            className={classes.menuButton}
            onClick={navigateBack}
            style={{ backgroundColor: '#fff' }}
            variant="outlined"
          >
            Back
          </Button>
          <Typography variant="h5">Product Detail</Typography>
        </div> */}
        {displayImage() != false &&
          <Grid container spacing={2} style={{ paddingTop: 8 }} direction="row">
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
              <Card className={classes.card} variant="outlined">
                <CardContent>
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <img
                      height={'100%'}
                      width={'100%'}
                      src={getImagePath()} />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
              <Card className={classes.card} variant="outlined" style={{ paddingBottom: 0 }}>
                <CardContent classes={{ root: classes.cardContent }}>
                  <Grid container className={classes.row}>
                    <Typography variant="h6" style={{ fontSize: 22 }} >
                      {productData ? productData.name ? productData.name : '' : ''}
                    </Typography>
                  </Grid>
                  <Grid container className={classes.row}>
                    {displayPrice()}
                  </Grid>
                  <Grid container className={classes.row}>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                      {/* <Typography variant="h6">BARCODE</Typography> */}
                    </div>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        }
        {displayImage() === false &&
          <Grid container spacing={2} style={{ paddingTop: 8 }} direction="row">
            <Grid item xs={12} >
              <Card className={classes.card} variant="outlined">
                <CardContent classes={{ root: classes.cardContent }} >
                  <Grid container className={classes.noImageRow}>
                    <Typography variant="h6">{productData ? productData.name ? productData.name : '' : ''}</Typography>
                  </Grid>
                  <Grid container className={classes.noImageRow}>
                    {displayPrice()}
                  </Grid>
                  <Grid container className={classes.row}>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                    </div>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        }
        {/* </Grid> */}
      </Paper>
    </Container>

  );

};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData.authData,

  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    setPageLoader: (isLoading: boolean) => dispatch(setPageLoading(isLoading))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);