import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Paper, Divider } from '@material-ui/core';
import { Theme, createStyles, makeStyles, fade } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import ImageIcon from '@material-ui/icons/Image';
import HomeIcon from '@material-ui/icons/Home';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Chip from '@material-ui/core/Chip';
import ListSubheader from '@material-ui/core/ListSubheader';
import InfoIcon from '@material-ui/icons/Info';

import { Product, CommodityGroup } from '../../../products/models';
import { PaginatedResponse, Location } from '../../../shared/models';

import { getProductsByPage, getCommodityGroups, getKioskProducts } from '../../../products/api/Api';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import composeRequest from '../../../shared/api/core';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import cloneDeep from 'lodash/cloneDeep';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            padding: theme.spacing(2),
            backgroundColor: '#f6f7fC',
            height: '100%',
            overflow: 'auto'
        },
        tags: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
    }),
);

interface GroupedProducts {
    loading: boolean;
    products: PaginatedResponse<Product>;
    commodityGroup: CommodityGroup;
}

const ProductList = (props: any) => {
    // console.log(props);
    const classes = useStyles({});
    const [products, setProducts] = useState([]);
    const [commodityGroups, setCommodityGroups] = useState([]);
    const [groupedProducts, setGroupedProducts] = useState({});

    useEffect(() => {
        console.log('groupedProducts', groupedProducts);
    }, [groupedProducts]);

    useEffect(() => {
        const sb = props.userData.selectedBusiness;
        // getProductsByPage(1, 24, null, sb.business_id)
        //     .catch(handleError) // handle network issues
        //     .then(checkStatus)
        //     .then(parseJSON)
        //     .then((data: PaginatedResponse<Product>) => {
        //         setErrorStatusVisible(false);
        //         setEmptyDataSourceMessage(EMPTY_ROW_MSG);
        //         setProducts(data.results);
        //     })
        //     .catch((error: any) => {
        //         if (error) {
        //             if (error.status && error.status === 500) {
        //                 setEmptyDataSourceMessage(ERROR_500);
        //                 setErrorStatusVisible(false);
        //             } else if ((error.status === 403 || error.status === 401)
        //             ) {
        //                 setEmptyDataSourceMessage(ERROR_MSG);
        //                 setErrorStatusVisible(true);
        //             } else {
        //                 setEmptyDataSourceMessage(ERROR_MSG);
        //                 setErrorStatusVisible(false);
        //             }
        //         }
        //     });
        getKioskProducts(1, 8, null, sb.business_id, null)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((response: any) => {
                setGroupedProducts(response);
            })
            .catch((error: any) => { });
        getCommodityGroups(null, sb.business_id)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: CommodityGroup[]) => {
                setCommodityGroups(data);
                // const groups = data.length > 4 ? data.slice(0, 4) : [...data];
                // groups.forEach(c => {
                //     const m = { ...groupedProducts };
                //     // m[c.name] = [];
                //     getKioskProducts(1, 8, null, sb.business_id, c.uuid)
                //         .catch(handleError) // handle network issues
                //         .then(checkStatus)
                //         .then(parseJSON)
                //         .then((response: PaginatedResponse<Product>) => {
                //             if (response.results.length > 0) {
                //                 m[c.name] = response.results;
                //                 setGroupedProducts(m);
                //             }
                //         })
                //         .catch((error: any) => { });
                // });
                // console.log(groupedProducts);
            })
            .catch((error: any) => { });
    }, [props.userData.selectedBusiness]);

    const groups = Object.keys(groupedProducts);

    // const navigateToProductDetailScreen = (rowData: any) => {
    //     const productDetailUrl = '/web/products/' + rowData.uuid;
    //     props.history.push(productDetailUrl, rowData);
    // };
    const getImagePath = () => {
        const imagePath = '/assets/img-default.png';
        return window.location.origin + imagePath;
      };

    return (
        <div style={{ width: '100%' }}>
            <div style={{ width: '100%' }}>
                <div style={{
                    marginBottom: 12,
                    padding: 16,
                    backgroundColor: '#fff',
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    borderRadius: '4px'
                }}>
                    <Grid container className={classes.tags} spacing={2}>
                        <Grid item xs={12}>
                            <Grid container justify="center" spacing={2}>
                                {commodityGroups.map((c, index) => (
                                    <Grid key={index} item>
                                        <Chip label={c.name} clickable />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    {
                        groupedProducts &&
                        Object.entries(groupedProducts).length > 0 &&
                        Object.entries(groupedProducts).map((item: [string, Product[]], i: number) => {
                            // console.log(item);
                            return (
                                <div key={item[0]} >
                                    {/* <GridList cols={5}> */}
                                    <Typography variant='h5' style={{ marginBottom: 10 }}>{item[0]}</Typography>
                                    {/* </GridList> */}
                                    <GridList cellHeight={180} cols={5}>
                                        {
                                            item[1].map((p, index) => {
                                                console.log(p);
                                                return (
                                                    <GridListTile style={{
                                                        padding: 0,
                                                        backgroundColor: 'white',
                                                        height: 220,
                                                        width: 220,
                                                        margin: '16px',
                                                        borderRadius: 4,
                                                        boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
                                                    }}
                                                        key={index}>
                                                        {/* {
                                                            !p.image &&
                                                            <div style={{ width: '100%', height: 'calc(100% - 68px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <ImageIcon style={{ color: '#cdcdcd', fontSize: '72' }} />
                                                            </div>
                                                        } */}
                                                        <img src={getImagePath()} alt={p.name} />
                                                        <GridListTileBar
                                                            style={{ textAlign: 'center', borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }}
                                                            title={<Typography variant="subtitle2">{p.name}</Typography>}
                                                            subtitle={<Typography variant="subtitle1">{`$4.99`}</Typography>}
                                                        />
                                                    </GridListTile>
                                                );
                                            })
                                        }
                                    </GridList>
                                </div>
                            );
                        })
                    }
                </div>
            </div>

        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userData: state.userLocalData,
    };
};

export default connect(mapStateToProps, null)(ProductList);