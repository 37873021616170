
import { combineReducers, Action } from 'redux';
import {
    GET_USERS, GET_USERS_FAILURE, GET_USERS_SUCCESS, UPDATE_USER,
    GET_USER_ACTIVE_COUNT, GET_USER_ACTIVE_COUNT_SUCCESS, GET_USER_ACTIVE_COUNT_FAILURE,
    GET_USER_SUMMARY, GET_USER_SUMMARY_FAILURE, GET_USER_SUMMARY_SUCCESS
} from '../actions/actionTypes';
import { User } from '../../models';


export interface InitialState {
    data: User[]
    selectedUser: User | null
    isFetching: false
    success: false
    error: false
    errorData: any
}

const initialState: InitialState = {
    data: [] as User[],
    selectedUser: null,
    isFetching: false, 
    success: false,
    error: false,
    errorData: null
}


export function userListReducer(state = initialState, action: any) {
    switch(action.type) {
        case GET_USERS: return {
            ...state, 
            isFetching: true, 
            success: false,
            error: false,
            errorData: null
        }
        case GET_USERS_SUCCESS: return {
            ...state,
            isFetching: false,
            data: action.data.results,
            success: true,
            error: false,
            errorData: null
        }
        case GET_USERS_FAILURE: return {
            ...state,
            isFetching: false,
            success: false,
            error: true,
            errorData: action.err ? action.err : null
        }
        case UPDATE_USER:
            if (action.data) {
                const newState = {...state};
                const userList = [...newState.data]
                for (let i = 0; i < userList.length; i++) {
                    if (userList[i].id === action.data.id) {
                        userList[i] = action.data
                    }
                }
                return {
                    ...state,
                    isFetching: false,
                    data: userList,
                    success: true,
                    error: false,
                    errorData: null
                }
            }
        // case DELETE_DEVICE:
        //     if (action.data) {
        //         const newState = {...state};
        //         const userList = [...newState.data]
        //         for (let i = 0; i < userList.length; i++) {
        //             if (userList[i].id === action.data.id) {
        //                 userList.splice(i, 1)
        //             }
        //         }
        //         return {
        //             ...state,
        //             isFetching: false,
        //             data: userList,
        //             success: true,
        //             error: false
        //         }
        //     }
            
        default: return state;

    }
}
const initialUserCountState = {
    activeUserCount: 0,
    isFetching: false,
    success: false,
    error: false,
}

export function userCountReducer(state=initialUserCountState, action: any) {
    switch(action.type) {
        case GET_USER_ACTIVE_COUNT: return {
            ...state, 
            isFetching: true, 
            success: false,
            error: false
        }
        case GET_USER_ACTIVE_COUNT_SUCCESS: return {
            ...state,
            isFetching: false,
            activeUserCount: action.data.activeUserCount,
            success: true,
            error: false
        }
        case GET_USER_ACTIVE_COUNT_FAILURE: return {
            ...state,
            isFetching: false,
            success: false,
            error: true
        }
        default: return state;
    }
}

const initialUserSummaryState = {
    activeUserCount: 0,
    deviceAccessCount: 0,
    webAccessCount: 0,
    isFetching: false,
    success: false,
    error: false,
    errorData: null
}

export function userSummaryDataReducer(state=initialUserSummaryState, action: any) {
    switch(action.type) {
        case GET_USER_SUMMARY: return {
            ...state, 
            isFetching: true, 
            success: false,
            error: false,
            errorData: null
        }
        case GET_USER_SUMMARY_SUCCESS: return {
            ...state,
            isFetching: false,
            ...action.data,
            success: true,
            error: false,
            errorData: null
        }
        case GET_USER_SUMMARY_FAILURE: return {
            ...state,
            isFetching: false,
            success: false,
            error: true,
            errorData: action.err ? action.err : null
        }
        default: return state;
    }
}



export const userModuleReducer = combineReducers({
    userList: userListReducer,
    userCount: userCountReducer,
    userSummaryData: userSummaryDataReducer
});