export interface PaginatedResponse<T> {
    count: number;
    next: string | null;
    previous: string | null;
    results: T[];
}

export interface Location {
    id: number;
    name: string;
    location_code: any;
    number: number;
    is_deleted: boolean;
    business_id: string;
    business: any;
    business_name?: string;
    is_supplier?: boolean;
    korona_org_unit: string;
    selected: boolean;
}

export interface Business {
    id: number;
    name: string;
    company: string;
    domain: string;
    business_settings?: any;
    parser_settings?: any;
}

export interface LocationBusiness {
    business_id: number;
    business_name: string;
    is_supplier?:boolean;
    selected?: boolean;
}

export interface ErrorCode {
    name: string;
    code: string;
}

export class ClassWithStaticPermission {
    static CAN_VIEW_MATCHED_PRODUCT_DESC : string = 'can_view_matched_product_desc'
}
export interface Permission {
    id: number;
    name: string;
    codename: string;
}
export const ERROR_CODES = {
    NO_PERMISSIONS: 'konnect_error_0009'
};

export interface ToastType {
    open: boolean;
    message: string;
    variant: 'success' | 'error';
};