import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import { labelerReducer } from '../modules/labeler/data/reducers/labelerReducer';
import { uiLoaderReducer } from './reducers/';
import { userDataReducer } from './reducers/kiosk/userDataReducer';


import { deviceModuleReducer } from '../modules/devices/data/reducers/deviceReducer';
import { inventoryReducer } from '../modules/inventory/data/reducers/inventoryReducer';
import { masterModuleReducer } from '../modules/master-data/data/reducers';
import productByCodeReducer from '../modules/products/data/reducers/productByCodeReducer';
import { productsModuleReducer } from '../modules/products/data/reducers/productReducer';
import { sharedModuleReducer } from '../modules/shared/data/reducers';
import { stockOrderModuleReducer } from '../modules/stockOrder/data/reducers';
import { stockReceiptModuleReducer } from '../modules/stockReceipt/data/reducers';
import { userModuleReducer } from '../modules/users/data/reducers/userModuleReducer';
import { toastReducer } from '../modules/shared/components/Notifier/redux/reducers';

const appReducer = combineReducers({
    product: productByCodeReducer,
    productData: productsModuleReducer,
    toast: toastReducer,
    uiLoader: uiLoaderReducer,
    userLocalData: userDataReducer,
    userModuleData: userModuleReducer
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
        'product', 'productData',
    ]
};

const rootReducer = (state: any, action: any) => {
    if (action.type === 'LOG_OUT') {
        state = undefined;
    }

    return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
    let store = createStore<any, any, any, any>(persistedReducer, compose(applyMiddleware(thunk)));
    let persistor = persistStore(store);
    return { store, persistor };
};